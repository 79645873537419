.landingPageWrapper {
  $medium: 768px;
  min-height: 100vh;

  .dropdown-toggle::after {
    display: none;
  }

  .text-decoration-none {
    text-decoration: none !important;
  }

  /* Font Awesome Icons have variable width. Added fixed width to fix that.*/
  .icon-width {
    width: 2rem;
  }

  * {
    transition: 0.3s !important;
  }

  .navbar .navbar-toggler .navbar-toggler-icon {
    width: 22px;
    height: 25px;
    outline: 0;
    background-color: white;
    display: block;
    border-radius: 1px;
  }

  .landingBody {
    margin-top: 3.5rem;

    .welComeContainer {
      .carousel-item {
        display: unset !important;
      }

      .imageWrapper {
        height: 800px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 600px;

          @media screen and (max-width: $medium) {
            height: 300px;
            margin-top: 5rem;
          }
        }

        @media screen and (max-width: $medium) {
          height: 300px;
        }
      }

      .dataPart {
        height: 800px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;

        @media screen and (max-width: $medium) {
          text-align: center;
        }

        .welcomeHeader {
          margin-bottom: 1.8rem;
          font-size: 2.5rem;
          color: rgb(49, 49, 49);
        }

        .smallHorizontalDivider {
          width: 220px;
          height: 4px;
          background-color: rgb(255, 103, 103);
          margin-top: -1rem;
          margin-bottom: 2.5rem;

          @media screen and (max-width: $medium) {
            margin: auto;
            margin-top: -1rem;
            margin-bottom: 2.5rem;
          }
        }

        .welcomeText {
          margin-bottom: 2rem;
          font-size: 1rem;
          color: rgb(49, 49, 49);
        }

        .startNowBtn {
          margin-top: 1rem;
          border: none;
          border-radius: 7px;
          color: white;
          font-size: 0.8rem;
          padding: 6px 25px;
          margin-right: 15px;
          background-color: rgb(255, 112, 112);
          font-weight: 600;
          margin-bottom: 15px;
          box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);

          &:hover {
            box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.05);
          }

          @media screen and (max-width: $medium) {
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }

    .topContainer {
      min-height: 30vh;
      background-color: rgb(255, 103, 103);
      padding-top: 8rem;
      padding-bottom: 8rem;

      .topHeaderWrapper {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;

        .topHeader {
          margin-bottom: 2rem;
          font-size: 2.5rem;
          color: white;
        }

        .smallHorizontalDivider {
          width: 120px;
          height: 4px;
          background-color: white;
          margin-top: -1rem;
          margin-bottom: 2.5rem;
        }

        @media screen and (max-width: $medium) {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;

          * {
            text-align: center;
          }
        }

        .topText {
          color: white;
        }

        .actionWrapper {
          margin-top: 1.5rem;

          @media screen and (max-width: $medium) {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin-bottom: 5rem;
          }

          .topActionButtons {
            border: none;
            border-radius: 4px;
            font-size: 0.8rem;
            padding: 6px 25px;
            margin-right: 15px;
            background-color: white;
            font-weight: 600;
            box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.2);

            &:hover {
              box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.05);
            }
          }
        }
      }

      .topHeaderImageWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -3.8rem;

        @media screen and (max-width: $medium) {
          margin-top: 0rem;
        }

        img {
          width: 330px;
          min-width: 300px;
        }
      }
    }

    .aboutWrapper {
      padding-top: 6rem;
      padding-bottom: 6rem;

      .aboutHeader {
        font-size: 2.4rem;
        color: black;
        font-weight: 600;
        margin-top: 1rem;
        margin-bottom: 3rem;

        .highlightedText {
          color: #EA5A58;
        }

        margin-bottom: 1rem;
      }

      .smallHorizontalDivider {
        width: 120px;
        height: 4px;
        background-color: rgb(255, 103, 103);
      }

      .aboutText {
        margin-top: 2.5rem;
      }

      .aboutVideoWrapper {
        margin-top: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;

        .aboutVideoBox {
          background-color: darkcyan;
          width: 400px;
          height: 280px;
          display: flex;
          justify-content: center;
          border-radius: 15px;
          align-items: center;
        }

      }

      .aboutUsCarousel {
        min-height: 550px;

        .carousel-indicators {

          // background-color: darkcyan;
          li {
            background-color: rgba(255, 100, 100, 0.231);
            cursor: pointer;

            &.active {
              background-color: rgb(255, 93, 93);
            }
          }
        }
      }

      .aboutCarouselItemWrapper {

        padding: 8rem 0 0 0;
        // margin-bottom: 5rem;
        width: 100%;
        // height: 500px;
        background-color: rgb(255, 255, 255);

        .aboutCarouselImageWrapper {
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 280px;
          }
        }

        .aboutCarouselHeader {
          font-size: 1.8rem;
          color: black;
          font-weight: 600;

          margin-bottom: 1.2rem;

        }

        .aboutCarouselSubHeader {
          font-size: 1.4rem;
          color: black;
          font-weight: 600;
          margin-top: 1rem;
        }

        .aboutText {
          font-size: 0.9rem;
          color: black;
          font-weight: 400;
        }

        .startNowBtn {
          margin-top: 1rem;
          border: none;
          border-radius: 7px;
          color: white;
          font-size: 0.8rem;
          padding: 6px 25px;
          margin-right: 15px;
          background-color: rgb(255, 112, 112);
          font-weight: 600;
          margin-bottom: 15px;
          box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);

          &:hover {
            box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.05);
          }
        }
      }
    }
  }

  .keyFeaturesWrapper {
    .keyFeaturesTitle {
      font-size: 2.4rem;
      color: black;
      font-weight: 600;
      margin-top: 1rem;
      margin-bottom: 3rem;

      .highlightedText {
        color: #EA5A58;
      }

      margin-bottom: 1rem;

      @media screen and (max-width: $medium) {
        text-align: center;
        width: 100%;
      }
    }

    .smallHorizontalDivider {
      width: 120px;
      height: 4px;
      background-color: rgb(255, 103, 103);

      @media screen and (max-width: $medium) {
        margin: auto;
      }
    }

    .keyFeatureItem {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 5rem;

      .imageWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 200px;
        border-radius: 200px;
        background-color: rgb(255, 147, 147);

        img {
          width: 100px;
        }

        &.blue {
          background-color: #ACD1E5;
        }

        &.yellow {
          background-color: #F6BE6E;
        }

        &.violet {
          background-color: #BABADD;
        }

        margin-bottom: 4rem;
      }

      h4 {
        font-size: 1.3rem;
        font-weight: 600;

        .highlightedText {
          text-align: center;
          color: #EA5A58;
        }
      }

      p {
        font-size: 1rem;
        text-align: center;
        margin-top: 1rem;
      }
    }

    padding-bottom: 6rem;
  }

  .queueungExpWrapper {
    padding: 6rem 0;
    background-color: whitesmoke;

    .queueingExpHeader {
      text-align: center;
      font-size: 2.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: black;
      font-weight: 600;
      margin-top: 1rem;
      margin-bottom: 3rem;

      h2 {
        margin-bottom: 2rem;
      }

      .highlightedText {
        color: #EA5A58;
      }

      margin-bottom: 1rem;
    }

    .smallHorizontalDivider {
      width: 300px;
      height: 4px;
      background-color: rgb(255, 103, 103);
    }

    .mapWrapperCol {
      display: flex;
      justify-content: center;
      align-items: center;

      .mapWrapper {
        margin-top: 6rem;
        background-image: url("../../../../assets/img/queue-system-map.png");
        width: 700px;
        height: 450px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: relative;

        .mapPointOuterWrapper {
          cursor: pointer;
          position: absolute;
          background-color: rgba(255, 103, 103, 0.482);
          width: 60px;
          height: 60px;
          border-radius: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          animation-name: backgroundColorPalette;
          animation-duration: 0.6s;
          animation-iteration-count: infinite;
          animation-direction: alternate;

          .mapPointInnerWrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            border-radius: 40px;
            position: relative;
            background-color: rgba(255, 103, 103, 0.884);

            .mapPointer {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 20px;
              height: 20px;
              border-radius: 20px;
              background-color: white;
              font-size: 0.8rem;
              font-weight: 600;
              color: rgb(255, 103, 103);
            }

            .pointerText {
              position: absolute;
              top: 10px;
              height: 0;
              box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
              left: 10px;
              padding: 0;
              border-radius: 8px;
              background-color: white;
              font-size: 0rem;
              color: rgba(255, 255, 255, 0);
              width: 0;
              font-weight: 600;
              transition: none !important;

              &.active {
                height: unset;
                width: 200px;
                color: black;

                font-size: 0.8rem;
                padding: 10px 30px;
                transition: 0.2s !important;
              }
            }
          }
        }

        .point1 {
          bottom: 170px;
          right: 100px;
        }

        .point2 {
          bottom: 80px;
          right: 290px;
        }

        .point3 {
          bottom: 270px;
          right: 250px;
        }

        .point4 {
          bottom: 360px;
          right: 180px;
        }

        .point5 {
          bottom: 287px;
          right: 380px;
        }
      }
    }

  }

  .productFeaturesWrapper {
    // background-color: whitesmoke;
    padding: 6rem 0;

    .productFeatureHeaderText {
      font-size: 2.4rem;
      color: black;
      font-weight: 600;
      margin-top: 1rem;
      margin-bottom: 1rem;

      .highlightedText {
        color: #EA5A58;
      }

      @media screen and (max-width: $medium) {
        text-align: center;
      }

    }

    .smallHorizontalDivider {
      width: 100px;
      height: 4px;
      background-color: rgb(255, 103, 103);

      @media screen and (max-width: $medium) {
        margin: auto;
      }
    }

    .dataPartWrapper {
      padding-top: 3rem;
      height: 400px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .tabSwitcher {
        display: flex;
        font-size: 0.7rem;
        height: 40px;
        font-weight: 600;
        background-color: rgb(216, 216, 216);
        border-radius: 60px;
        padding: 2px 15px;
        align-items: center;
        cursor: pointer;
        width: 400px;
        justify-content: space-between;

        @media screen and (max-width: $medium) {
          width: 90vw;
          height: 65px;
        }

        .tab {
          @media screen and (max-width: $medium) {
            text-align: center;
          }

          &.active {
            padding: 6px 15px;
            border-radius: 60px;
            background-color: white;
            color: rgb(245, 22, 22);
            box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
          }
        }
      }

      .contentTitle {
        font-size: 1.6rem;
        color: black;
        font-weight: 600;
        margin-top: 3rem;

        @media screen and (max-width: $medium) {
          text-align: center;
          width: 100%;
        }
      }

      .contentText {
        font-size: 0.9rem;
        color: black;
        margin-top: 2rem;

        @media screen and (max-width: $medium) {
          text-align: center;
          width: 100%;
        }
      }
    }

    .featureImageWrapper {
      height: 400px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        width: 220px;
      }
    }

    .featureStartBtn {
      margin-top: 1rem;
      border: none;
      border-radius: 7px;
      color: white;
      font-size: 0.8rem;
      padding: 6px 25px;
      margin-right: 15px;
      background-color: rgb(255, 112, 112);
      font-weight: 600;
      margin-bottom: 15px;
      box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);

      &:hover {
        box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.05);
      }

      @media screen and (max-width: $medium) {
        width: 100%;
        margin: auto;
      }
    }
  }

  .perfectSolutionWrapper {
    padding: 5rem 0;
    background-color: rgb(231, 231, 231);

    .perfectSolutionHeader {
      font-size: 2.4rem;
      color: black;
      font-weight: 600;
      margin-top: 1rem;
      margin-bottom: 1rem;

      .highlightedText {
        color: #EA5A58;
      }

      @media screen and (max-width: $medium) {
        text-align: center;
      }

    }

    .smallHorizontalDivider {
      width: 100px;
      height: 4px;
      background-color: rgb(255, 103, 103);

      @media screen and (max-width: $medium) {
        margin: auto;
      }
    }

    .dataPart {
      height: 300px;

      #perfectSolutionCarousel {
        min-height: 250px;
      }

      @media screen and (max-width: $medium) {
        padding: 0 2rem;
      }

      padding-top: 3rem;

      .slideText {
        width: 100%;

        p {
          margin-top: 1.6rem;
        }
      }

      .carousel-indicators {

        // background-color: darkcyan;
        li {
          background-color: rgba(255, 100, 100, 0.231);
          cursor: pointer;

          &.active {
            background-color: rgb(255, 93, 93);
          }
        }
      }
    }

    .imagePart {
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 250px;
      }
    }
  }

  .suggestedAppsWrapper {
    padding: 5rem 0;

    .suggestedAppsHeader {
      @media screen and (max-width: $medium) {
        text-align: center;
        margin-bottom: 2.5rem;
      }
    }

    .suggestedAppsTabsWrapper {
      margin-top: -1rem;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      @media screen and (max-width: $medium) {
        flex-wrap: wrap;
        justify-content: center;
      }

      .tab {
        margin: 15px 30px;
        padding-bottom: 10px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-bottom: 2px solid rgb(255, 255, 255);
        justify-content: center;

        .icon {
          font-size: 4rem;
        }

        .label {
          margin-top: -3px;
          font-size: 0.8rem;
        }

        &.active {
          border-bottom: 2px solid rgb(255, 103, 103) !important;
        }

        &:hover {
          border-bottom: 2px solid rgb(255, 188, 188);
        }
      }
    }

    .tabContentWrapper {
      margin-top: 3rem;

      .imageWrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 500px;

          @media screen and (max-width: $medium) {
            height: unset;
            width: 90vw;
          }
        }
      }

      .dataWrapper {
        .tabTitle {
          font-size: 2rem;
          font-weight: 600;
          margin-bottom: 1rem;

          @media screen and (max-width: $medium) {
            text-align: center;
          }
        }

        .smallHorizontalDivider {
          width: 100px;
          height: 4px;
          background-color: rgb(255, 103, 103);

          @media screen and (max-width: $medium) {
            margin: auto;
          }
        }

        .tabText {
          margin-top: 4rem;
          font-size: 0.9rem;

          @media screen and (max-width: $medium) {
            text-align: center;
          }
        }
      }
    }
  }

  .suggestedAccessoriesWrapper {
    padding: 5rem 0;

    .headetText {
      font-size: 2.4rem;
      color: black;
      font-weight: 600;
      margin-top: 1rem;
      margin-bottom: 1rem;

      .highlightedText {
        color: #EA5A58;
      }

      @media screen and (max-width: $medium) {
        text-align: center;
      }
    }

    .headerDivider {
      margin-top: 1rem;
      margin-bottom: 3rem;
    }

    .smallHorizontalDivider {
      width: 100px;
      height: 4px;
      background-color: rgb(255, 103, 103);

      @media screen and (max-width: $medium) {
        margin: auto;
      }
    }

    .imageWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .dataPart {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      text-align: center;

      .title {
        margin-top: 35px;
        font-size: 1.4rem;
        margin-bottom: 10px;
      }

      .text {
        margin-top: 1.7rem;
        font-size: 0.8rem;
        padding: 0 35px;
        font-weight: 400;
        height: 100px;
      }

      .learnMoreBtn {
        margin-top: 1rem;
        border: none;
        border-radius: 7px;
        color: white;
        font-size: 0.8rem;
        padding: 6px 25px;
        margin-right: 15px;
        background-color: rgb(100, 230, 147);
        font-weight: 600;
        margin-bottom: 15px;
        box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);

        &:hover {
          box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.05);
        }
      }
    }

  }

  .footer {
    background-color: rgba(218, 218, 218, 0.2);
    padding: 4rem 0;

    .socialMediaIcons {
      display: flex;
      justify-content: center;
      align-items: center;

      .socialIconWrapper {
        width: 30px;
        margin: 10px;
        height: 30px;
        border-radius: 30px;
        background-color: rgb(255, 103, 103);
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .footerLinksWrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      .footerLink {
        cursor: pointer;
        text-decoration: underline;
        margin: 10px;
        color: rgb(98, 98, 98);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .copyrightText {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;
      font-size: 0.7rem;
    }
  }
}
    .headerSignUpBtn {
      background-color: rgb(255, 112, 112);
      margin-top: 1rem;
          border: none;
          border-radius: 7px;
          color: white;
          font-size: 0.8rem;
          padding: 6px 25px;
          margin-left: 28px;
          margin-right: 15px;
          font-weight: 600;
          margin-bottom: 15px;
          box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
  
          &:hover {
            box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.05);
          }
    }



/* Standard syntax */
@keyframes backgroundColorPalette {
  0% {
    background: #ee6055;
  }

  5% {
    background: #ee5f55f5;
  }

  10% {
    background: rgba(238, 95, 85, 0.945);
  }

  15% {
    background: rgba(238, 95, 85, 0.877);
  }

  20% {
    background: rgba(238, 95, 85, 0.809);
  }

  25% {
    background: rgba(238, 95, 85, 0.795);
  }

  30% {
    background: rgba(238, 95, 85, 0.720);
  }

  35% {
    background: rgba(238, 95, 85, 0.650);
  }

  40% {
    background: rgba(238, 95, 85, 0.590);
  }

  45% {
    background: rgba(238, 95, 85, 0.530);
  }

  50% {
    background: rgba(238, 95, 85, 0.470);
  }

  55% {
    background: rgba(238, 95, 85, 0.440);
  }

  60% {
    background: rgba(238, 95, 85, 0.410);
  }

  65% {
    background: rgba(238, 95, 85, 0.380);
  }

  70% {
    background: rgba(238, 95, 85, 0.330);
  }

  75% {
    background: rgba(238, 95, 85, 0.300);
  }

  80% {
    background: rgba(238, 95, 85, 0.270);
  }

  85% {
    background: rgba(238, 95, 85, 0.240);
  }

  90% {
    background: rgba(238, 95, 85, 0.210);
  }

  95% {
    background: rgba(238, 95, 85, 0.180);
  }

  100% {
    background: rgba(238, 95, 85, 0.150);
  }
}