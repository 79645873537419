#clinicEditorFormWrapper {
  position: relative;
  padding: 10px 35px;
  height: 100%;
  h3 {
    margin-bottom: 20px;
  }
  position: relative;
  .crossWrapper {
    position: absolute;
    top: 30px;
    right: 30px;
  }
  .promptFormWrapper {
    .formErrorText {
      width: 100%;
      margin-top: -16px;
      font-size: 0.6rem;
      margin-bottom: 15px;
      color: red;
      font-weight: 400;
      height: 10px;
      text-align: left;
    }

  }
  .formActionWrapper {
    position: absolute;
    width: 90%;
    bottom: 20px;
    left: 20px;
    margin-top: 60px;
    display: flex;
    .saveButton {
      flex: 3;
    }
    .discardButton {
      flex: 1;
    }
  }
  .clinicLogoUploaderWrapper {
    margin-bottom: -35px;
    .fileUploader {
      padding: 5px 45px;
      background-color: rgb(0, 110, 255);
      border-radius: 5px;
      border: none;
      margin-top: 35px;
      cursor: pointer;
      color: white;
    }
  }
  .customSettingsToggle {
    margin-top: 15px;
    margin-bottom: 15px;
    cursor: pointer;
  }
}