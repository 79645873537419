.imageUploaderWrapper {
    .selectImageMode {
        background-color: rgb(255, 255, 255);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 80px;
        border-radius: 8px;
        margin-top: 9px;
        cursor: pointer;
        border: 2px dashed rgb(87, 87, 87);

        &:hover {
            background-color: rgb(233, 233, 233);
        }
    }

    .imageEditMode {
        width: 100%;
        background-color: rgb(238, 238, 238);
        display: flex;
        // justify-content: center;
        align-items: center;
        height: 80px;

        img {
            height: 100%;
            object-fit: contain;
            margin-right: 25px;
            width: 35%;
        }

        .actions {
            display: flex;
            justify-content: center;
            align-items: center;

            // flex-direction: column;
            .changeBtn {
                background-color: rgb(255, 208, 0);
                color: black;
                font-weight: 600;
                border-radius: 5px;
                cursor: pointer;
                padding: 3px 25px;
                font-size: 0.8rem;
            }

            .removeBtn {
                margin-left: 15px;
                background-color: rgb(219, 0, 0);
                color: rgb(243, 243, 243);
                border-radius: 5px;
                cursor: pointer;
                padding: 3px 25px;
                margin-top: 3px;
                font-weight: 600;
                font-size: 0.8rem;
            }
        }
    }
}