$small: 500px;
$medium: 700px;

.clinicIdError {
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  text-align: center;
  line-height: 55px;
  font-weight: 500;
  flex-direction: column;

  i {
    font-size: 8rem;
  }

  p {
    margin-top: 1rem;
    font-size: 2.5rem;
    text-align: center;
    line-height: 55px;
    font-weight: 500;
  }
}

#wizardWrapper {
  background-color: white;
  position: relative;

  #startOver {
    height: 45px;
    font-size: 1rem;
    width: 150px;
    position: absolute;
    top: 107px;
    right: 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border: 1px solid #990000;
    color: #990000;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    .MuiSvgIcon-root {
      margin-right: 10px;
    }
  }
}

#wizradHeader {
  position: relative;

}

.MuiAutocomplete-proper {
  z-index: 66666666666;
}

.popper {
  z-index: 666666666
}


.clinicSwitcherWrapper {
  top: 18px;
  right: 203px;
  position: absolute;


  @media screen and (max-width: $medium) {
    top: 18px;
    right: 70px;
  
  }

  .clinicSwitcher {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    position: relative;
    border: 1px solid #990000;
    color: #990000;
    background-color: white;
    font-size: 0.8rem;
    padding: 4px 25px;
    border-radius: 70px;
    z-index: 9999;
    box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.2);

    @media screen and (max-width: $medium) {
      width: 130px;
    }

    .clinicLabel {
      padding-left: 10px;
    }

    .switcher {
      position: absolute;
      padding: 8px 15px;
      border: none;
      top: -12;
      background-color: #990000;
      box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.2);
      color: white;
      border-radius: 600px;
      cursor: pointer;
      right: -3px;
    }
  }
}


.tableActions {
  &.spaceBetween {
    justify-content: space-between;
    align-items: center;

    .addPatient {
      margin-top: 0 !important;
    }

    @media screen and (max-width: $medium) {
      flex-direction: column;
      .addPatient {
        margin-top: 20px !important;
      }
      .usersOptionsWrapper {
        margin-top: 40px !important;
      }
    }

  }

  &.centered {
    justify-content: center;
  }
}

.searchWrapper {
  border: 1px solid #990000;
  border-radius: 18px;
  width: 480px;
  height: 40px;
  background-color: white;
  padding: 5px 25px;
  display: flex;
  align-items: center;

  @media screen and (max-width: $medium) {
    width: 90vw;
  }

  .searchIconWRapper {
    flex: 1;
  }

  .clearBtn {
    flex: 1;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
  }

  input {
    background-color: white;
    border: none;
    flex: 13;
  }
}


.main-panel {
  height: unset !important;
  max-height: unset !important;
}