.landingPageWrapper {
  $medium: 768px;

  .contactFormOuterWrapper {
    width: 100vw;
    height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(226, 226, 226);

    .contactFormWrapper {
      box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.2);
      width: 800px;
      height: 500px;
      background-color: white;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 11px solid white;

      .imagePart {
        flex: 4;
        background-image: url("../../../../assets/img/contact-form.png");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 100%;
        padding-left: 30px;

        h4 {
          margin-top: 5rem;
        }

        p {
          font-size: 0.7rem;
        }
      }

      .formPart {
        flex: 7;

        .formInnerWrapper {
          width: 100%;
          height: 100%;
          padding: 0 15px;

          .formRow {
            display: flex;
            justify-content: space-evenly;
            align-items: center;

            .formCol {
              padding: 15px 13px;
              width: 100%;

              .inputWrapper {
                width: 100%;

                .label {
                  font-size: 0.8rem;
                }

                input,
                textarea {
                  width: 100%;
                  border: none;
                  border-bottom: 1px solid black;
                  padding-left: 6px;
                  font-size: 0.8rem;
                }
              }
            }
          }
        }

        .submitBtn {
          margin-left: 15px;
          margin-top: 1rem;
          border: none;
          border-radius: 7px;
          color: white;
          font-size: 0.8rem;
          padding: 6px 25px;
          margin-right: 15px;
          background-color: rgb(255, 112, 112);
          font-weight: 600;
          margin-bottom: 15px;
          box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);

          &:hover {
            box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.05);
          }

          @media screen and (max-width: $medium) {
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }
  }
}