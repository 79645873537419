$small: 500px;
$medium: 700px;


#extendedActionsWrapper {
  button {
    cursor: pointer!important;
    outline: none;
    &:hover {
      box-shadow: unset!important;
    }
  }
  height: 650px;

  .loaderWrapper {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;

    h5 {
      margin-left: 15px;
    }
  }

  .modalHeader {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .dismissBtn {
      background-color: rgb(250, 44, 44);
      border: none;
      border-bottom-left-radius: 8px;
      color: white;
      font-weight: 600;
      font-size: 0.9rem;
      padding: 4px 35px;
      cursor: pointer;
    }
  }

  .actionBar {
    margin-top: 0px;
    display: flex;
    align-items: center;


  @media screen and (max-width: $medium) {
    flex-direction: column;
  }

    .patientInfoWrapper {
      flex: 4;
      padding: 15px 40px;
      
      @media screen and (max-width: $medium) {
        padding: 15px 20px;
        margin-top: 2rem;
      }

      p {
        margin-bottom: 20px;
        font-weight: 500;
        font-size: 2.1rem;
      }

      .callNumber {
        font-size: 1.5rem;
        font-weight: 400;
      }

      .actionsLabel {
        font-size: 1.2rem;
        margin-top: 35px;
        width: 100%;
        text-align: center;
      }

    }

    .additionalActions {
      flex: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      @media screen and (max-width: $medium) {
        flex-direction: row;
        margin-bottom: 25px;
      }


      .videoCall {
        background-color: rgb(0, 202, 84);
        border: none;
        border-radius: 65px;
        box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.2);
        color: white;
        font-weight: 600;
        font-size: 0.9rem;
        padding: 5px 35px;
      }

      .callToAction {
        margin-top: 9px;
        background-color: rgb(42, 42, 42);
        border: none;
        border-radius: 65px;
        box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.2);
        color: white;
        font-weight: 600;
        font-size: 0.8rem;
        padding: 2px 20px;

        @media screen and (max-width: $medium) {
          margin-top: 0px;
          margin-left: 15px;
          padding: 5px 35px;

        }
      }
    }
  }

  .composeWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 35px;
    background-color: whitesmoke;
    border: 2px solid rgb(194, 194, 194);

    .inputWrapper {
      flex: 8;

      textarea {
        width: 100%;
        height: 10px;
        resize: none;
        background-color: whitesmoke;
        border: 2px solid whitesmoke;
        color: black;
        border-radius: 7px;
        padding: 12px 20px;
      }
    }

    .sendWrapper {
      flex: 2;
      display: flex;
      justify-content: center;
      align-items: end;
      margin-bottom: -40px;
      margin-right: -10px;

      button {
        background-color: rgb(0, 182, 45);
        border: none;
        box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.2);
        border-radius: 65px;
        color: white;
        font-weight: 600;
        font-size: 0.9rem;
        height: 60px;
        width: 60px;

        &.disable {
          background-color: grey;
        }
      }
    }
  }

  h3 {
    margin: 20px 35px;

  }

  .messageLogsWrapper {
    margin: 20px 35px;
    height: 330px;
    overflow-y: scroll;

    .noMessage {}

    .messageLog {
      // padding-left: 20px;
      // padding-right: 10px;
      margin-bottom: 14px;
      display: flex;

      &.sentWrapper {
        justify-content: flex-start;
      }
      &.recieveWrapper {
        justify-content: flex-end;
      }

      .sentContainer {
        background-color: rgb(245, 245, 245);
        padding: 8px;
        min-width: 300px;
        border-radius: 25px;
        border-bottom-left-radius: 0;
      }
      .recieveContainer {
        background-color: rgb(46, 126, 255);
        padding: 8px;
        min-width: 300px;
        max-width: 80%;
        border-radius: 25px;
        border-bottom-right-radius: 0;
        color: rgb(255, 255, 255);
        margin-right: 5px;
      }
      .messageHeader {
        padding: 3px 5px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        .typeLabel {
          font-size: 0.8rem;
        }
        .timeLabel {
          font-size: 0.8rem;
        }
      }
      .messageBody {
        padding: 7px 3px;
        font-style: 0.9rem;
        font-weight: 500;
      }
    }
  }
  .helperText {
    margin: 6px 35px -6px 35px;
    font-weight: 600;
    color: blue;
  } 
}