.authPageWrapper {
  $medium: 768px;
  $small: 450px;
  min-height: 100vh;
  width: 100vw;
  background-color: rgb(128, 104, 187);
  display: flex;
  justify-content: center;
  align-items: center;

  .fieldError {
    font-size: 0.7rem;
    color: red;
    font-weight: 600;
    margin-bottom: -1rem;
    height: 24px;
  }

  .socialAuthWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    .socialBtn {
      height: 30px;
      width: 30px;
      border-radius: 30px;
      color: black;
      font-size: 1.5rem;
      cursor: pointer;
      margin: 0 10px;
      margin-bottom: 50px;
      background-color: white;
      border: none;
    }
    margin-bottom: -2rem;
  }

  .connectWithLabel {
    margin-top: 2rem;
    color: rgb(78, 78, 78);
    font-size: 0.8rem;
    width: 100%;
    text-align: center;
    margin-bottom: 25px;
  }

  .authCardWrapper {
    width: 900px;
    min-height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;

    .formPart {
      flex: 5;
      border-radius: 9px;
      background-color: white;
      min-height: 600px;
      z-index: 600;
      margin-right: -50px;
      box-shadow: 8px 8px 25px rgba(0, 0, 0, 0.2);
    }

    .imagePart {
      z-index: 500;
      height: 500px;
      flex: 4;
      border-radius: 9px;
      background-color: rgb(255, 95, 98);
      background-image: url("../../../../assets/img/auth-form.svg");
      background-size: cover;
      background-position: center;

      h4 {
        font-size: 1rem;
        font-weight: 600;
        color: white;
      }

      padding: 2rem 5rem;

      @media screen and (max-width: $medium) {
        display: none;
      }
    }

    .therapyClinic {
      z-index: 500;
      height: 500px;
      flex: 4;
      border-radius: 9px;
      background-color: rgb(255, 95, 98);
      background-size: cover;
      background-position: center;
      display: flex;
      justify-content: center;
      align-items: center;

      h4 {
        font-size: 1rem;
        font-weight: 600;
        color: white;
      }

      img {
        width: 200px;
      }

      padding: 2rem 5rem;

      @media screen and (max-width: $medium) {
        display: none;
      }
    }

    .formPart {
      padding: 50px 80px;

      .logo {
        color: rgb(243, 40, 43);
      }

      .formWrapper {
        margin-top: 2rem;

        .inputWrapper {
          input {
            border: none;
            border-bottom: 1px solid black;
            width: 100%;
            background-color: white !important;
          }

          .label {
            margin-bottom: -6px;
          }

          margin-bottom: 1.5rem;
        }
      }

      .actionBar {
        margin-top: 2.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .signin {
          background-color: rgb(64, 214, 129);
          border: none;
          border-radius: 18px;
          cursor: pointer;
          color: white;
          font-size: 0.8rem;
          padding: 5px 25px;
        }

        p {
          cursor: pointer;
        }
      }

      .bottomBar {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 45px;
        font-size: 0.8rem;
        margin-top: 15px;
        background-color: rgb(243, 243, 243);
        border-radius: 9px;

        span {
          cursor: pointer;
          margin-left: 8px;
          color: rgb(243, 40, 43);
        }
      }

      @media screen and (max-width: $medium) {
        margin: 6rem 4rem;
      }

      @media screen and (max-width: $small) {
        margin: 6rem 1rem;
        padding: 10px 30px;
      }
    }
  }

  .jcCenter {
    justify-content: center !important;
  }

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
}