$toggle-background-color-on: dodgerblue;
$toggle-background-color-off: darkgray;
$toggle-control-color: white;
$toggle-width: 40px;
$toggle-height: 20px;
$toggle-gutter: 5px;
$toggle-radius: 50%;
$toggle-control-speed: .15s;
$toggle-control-ease: ease-in;

// These are our computed variables
// change at your own risk.
$toggle-radius: $toggle-height / 2;
$toggle-control-size: $toggle-height - ($toggle-gutter * 1.5);

.toggle-control {
  display: block;
  position: relative;
  padding-left: $toggle-width;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  input:checked ~ .control {
    background-color: $toggle-background-color-on;
    
    &:after {
      left: $toggle-width - $toggle-control-size - $toggle-gutter;
    }
  }

  .control {
    position: absolute;
    top: 0;
    left: 0;
    height: $toggle-height;
    width: $toggle-width;
    border-radius: $toggle-radius;
    background-color: $toggle-background-color-off;
    transition: background-color $toggle-control-speed $toggle-control-ease;

    &:after {
      content: "";
      position: absolute;
      left: $toggle-gutter;
      top: $toggle-gutter - 1;
      width: $toggle-control-size;
      height: $toggle-control-size;
      border-radius: $toggle-radius;
      background: $toggle-control-color;
      transition: left $toggle-control-speed $toggle-control-ease;
    }
  }
}

.editButton {
  background-color: rgb(0, 110, 255);
  color: white;
  font-weight: 500;
  border: none;
  padding: 6px 15px;
  border-radius: 4px;
  cursor: pointer;
  &:disabled {
    background-color: rgb(106, 120, 139);
    cursor: not-allowed;
  }
}

.clinicTableAction {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 15px;

  .newButton {
    background-color: rgb(1, 202, 85);
    color: white;
    font-weight: 500;
    border: none;
    padding: 8px 25px;
    border-radius: 4px;
    cursor: pointer;

  }
}

.enabled {
  background-color: green;
  color: white;
  width: 120px;
  font-weight: 600;
  padding: 2px 10px;
  border-radius: 15px;
  i {
    color: white;
  }
}

.disabled {
  background-color: rgb(185, 15, 0);
  color: white;
  width: 120px;
  font-weight: 600;
  padding: 2px 10px;
  border-radius: 15px;
  i {
    color: white;
  }
}

.clinicSelectorWrapper {
  width: 280px;
  height: 60px ;
  margin-left: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 35px;
  // background-color: yellow;
  padding-bottom: 16px;
}

.usersOptionsWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}