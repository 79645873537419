$medium: 768px;

.AboutUsContainer {
  .carousel-item {
    display: unset !important;
  }

  .imageWrapper {
    height: 800px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 400px;

      @media screen and (max-width: $medium) {
        height: 300px;
        margin-top: 5rem;
      }
    }

    @media screen and (max-width: $medium) {
      height: 300px;
    }
  }

  .dataPart {
    height: 800px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    @media screen and (max-width: $medium) {
      text-align: center;
    }

    .AboutUsHeader {
      margin-bottom: 1.8rem;
      font-size: 2.5rem;
      color: rgb(49, 49, 49);
    }

    .smallHorizontalDivider {
      width: 220px;
      height: 4px;
      background-color: rgb(255, 103, 103);
      margin-top: -1rem;
      margin-bottom: 2.5rem;

      @media screen and (max-width: $medium) {
        margin: auto;
        margin-top: -1rem;
        margin-bottom: 2.5rem;
      }
    }

    .AboutUsText {
      margin-bottom: 2rem;
      font-size: 0.8rem;
      color: rgb(49, 49, 49);
    }

    .startNowBtn {
      margin-top: 1rem;
      border: none;
      border-radius: 7px;
      color: white;
      font-size: 0.8rem;
      padding: 6px 25px;
      margin-right: 15px;
      background-color: rgb(255, 112, 112);
      font-weight: 600;
      margin-bottom: 15px;
      box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);

      &:hover {
        box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.05);
      }

      @media screen and (max-width: $medium) {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}