.tableWrapper {
  width: 100%;
  background-color: white!important;
  min-height: 100vh;
  padding: 8rem 3rem 2rem 3rem;
  @media only screen and (max-width: 760px) {
    padding: 8rem 1rem 2rem 1rem;
  }
  position: relative;
  .actionBar {
    position: absolute;
    top: 150px;
    right: 30px;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px 25px;
      font-weight: 600;
      border-radius: 100px;
      border: none;
      background-color: black;
      box-shadow: 3px 3px 15px  rgba(0,0,0,0.2);
      color: white;
      cursor: pointer;
      &:hover {
        box-shadow: 3px 3px 15px  rgba(0, 0, 0, 0);  
      }
    }
  }
  h3 {
    color: black;
  }
  #table {
    width: 100%;
    // min-height: 100vh;
    background-color: white;

    .actions {
      button {
        margin-right: 1rem;
        border-radius: 15px;
        font-size: 1.1rem;
        // box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.178);
        &.completeBtn {
          border: none;
          background-color: rgb(57, 189, 149)!important;
          padding: 6px 20px;
          color: white;
          &.smallBtn {
            padding: 4px 20px;
            font-size: 0.8rem;
          }
        }
        &.noAnswerBtn {
          border: none;
          background-color: rgb(255, 36, 36)!important;
          padding: 6px 20px;
          color: white;
        }
        &.call {
          border: none;
          background-color: rgb(47, 184, 138)!important;
          padding: 6px 20px;
          color: rgb(255, 255, 255);
        }
      }
    }

    table {
      width: 950px;
      background-color: white;

      @media only screen and (max-width: 768px) {
        width: 700px;
      }

      border-collapse: collapse;
      margin: 50px auto;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.116);
      border-radius: 5px;
    }

    /* Zebra striping */
    tr:nth-of-type(odd) {
      background: rgb(230, 230, 230);
    }

    th {
      background: #292929;
      color: white;
      font-weight: bold;
    }

    td,
    th {
      padding: 10px;
      // border-bottom: 1px solid rgb(22, 22, 22);
      text-align: left;
      font-size: 18px;
    }

    /* 
    Max width before this PARTICULAR table gets nasty
    This query will take effect for any screen smaller than 760px
    and also iPads specifically.
    */
    @media only screen and (max-width: 760px) {

      table {
        width: 100%;
      }

      /* Force table to not be like tables anymore */
      table,
      thead,
      tbody,
      th,
      td,
      tr {
        display: block;
      }

      /* Hide table headers (but not display: none;, for accessibility) */
      thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }

      tr {
        border: 1px solid #ccc;
      }

      td {
        /* Behave  like a "row" */
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50%;
      }

      td:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        /* Label the data */
        content: attr(data-column);

        color: #000;
        font-weight: bold;
      }

    }
  }
}