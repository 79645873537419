.callToActionPromptWrapper {
  button {
    cursor: pointer;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(80, 80, 80, 0.89);

    .modalWrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 500px;
      height: 300px;
      background-color: white;
      border-radius: 9px;

      .modalBody {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .loaderWrapper {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }

        .dismissBtn {
          position: absolute;
          top: 0;
          right: 0;
          border: none;
          outline: none;
          background-color: red;
          border-radius: 0px;
          color: white;
          font-size: 0.8rem;
          padding: 3px 12px;
          border-bottom-left-radius: 11px;
        }

        .dialerWrapper {
          width: 80%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          input {
            width: 100%;
            border: 2px solid rgb(0, 0, 0);
            border-radius: 9px;
            text-align: center;
            font-size: 1.7rem;
            padding: 7px;
          }

          button {
            border: 2px solid black;
            border-radius: 9px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            padding: 6px 45px;
            color: white;
            background-color: black;
          }
        }

        .hotKeysWrapper {
          margin-top: 3rem;
          margin-bottom: -2rem;
          height: 35px;

          .hotKey {
            margin: 0 12px;
            border-radius: 3px;
            border: none;
            background-color: black;
            color: white;
            font-size: 0.8rem;
            font-weight: 600;
            padding: 4px 22px;

            &.suicideBtn {
              background-color: rgb(190, 0, 0);
            }
          }

        }
      }
    }
  }
}