.patientStatusPageWrapper {
  margin-top: 0px;
  $small: 700px;


  .header {
    background-color: #990000;
    color: white;
    padding: 13px 25px 10px 25px;
    text-align: center;

    h3 {
      font-weight: 800 !important;
    }
  }

  .pageBody {
    // height: calc(100vh - 75px);
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;

    @media screen and (max-width: $small) {
      flex-direction: column;
      min-height: 100vh;
      height: unset;

    }

    .leftPart {
      background-color: #990000;
      height: 100%;

      @media screen and (max-width: $small) {
        width: 100%;
      }

      flex: 2;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      .labHeaderLabel {
        color: white;
        font-weight: 400;
        font-size: 2.1rem;
        text-align: center;
      }

      .patientDetailsWrapper {
        margin-top: 4rem;

        .avatarWrapper {
          width: 150px;
          height: 150px;
          background-color: white;
          border-radius: 150px;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url("https://i.pinimg.com/originals/11/df/2b/11df2bc889722dab6946142dc9c70151.gif");
          box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.15);

          img {
            border-radius: 150px;
            height: 100%;
            // width: 150px;
          }
        }

        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        color: white;

        .patientName {
          margin-top: 2rem;
          font-size: 1.8rem;
          // font-weight: 600;
        }

        .callNumber {
          margin-top: 0.5rem;
          font-size: 1.3rem;
          // font-weight: 600;
        }
      }
    }

    .rightPart {
      padding: 15px 55px;
      background-color: rgb(255, 255, 255);
      // height: calc(100vh - 75px);
      height: 100vh;
      overflow-y: scroll;
      flex: 3;

      .loaderWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        .loaderText {
          margin-left: 1rem;
          font-weight: 600;
        }
      }

      @media screen and (max-width: $small) {
        width: 100%;
        height: unset;
        overflow-y: unset;
      }
    }
  }

  .outer {
    height: 100vh;
    min-width: 20vw;
    flex: 1;
  }

  .ongoingtext {
    font-weight: 400;
    color: black;
    font-size: 0.9rem;
  }

  .stepTime {
    font-size: 0.7rem;
    font-weight: 600;
    color: rgb(104, 104, 104);
  }

  .right {
    div {
      font-size: 1.2rem;
      //  font-weight: 600;
    }
  }

  $strip-color: rgb(0, 160, 24);
  $current-color: rgb(0, 197, 30);

  .progress {
    $gap: 20px;
    $line-height: 20px;
    $bullet-radius: 5px;
    $line-thick: 2px;

    $next-color: #666;
    background-color: white !important;

    $prev-color: #333;


    display: inline-flex;
    height: 100%;
    padding: 5vh 10%;

    >div {
      display: flex;
      flex-direction: column;
      color: $prev-color;

      &.left {
        padding-right: $gap;
        text-align: right;

        // Line
        div {
          &:last-of-type:after {
            display: none;
          }

          &:after {
            content: "";
            background: fade_out($strip-color, .9); //rgba(0, 0, 0, 0.6);
            border-radius: 2px;
            position: absolute;
            right: -$gap;
            top: $line-height/2;
            height: 101%;
            width: 1px;
            transform: translateX(50%);
          }
        }
      }

      &.right {
        padding-left: $gap;

        div {
          &.prev {
            &:after {
              transition: none;
            }
          }

          &.current {
            color: rgb(46, 212, 0);

            font-weight: bold;

            &:before {
              background: $current-color;
              padding: $bullet-radius * 2;
              transition: all 0.2s .15s cubic-bezier(0.175, 0.885, 0.32, 2);
            }

            &:after {
              height: 0%;
              transition: height .2s ease-out;
            }

            &.error {
              color: rgb(245, 0, 0);

              &:before {
                background: rgb(245, 0, 0);
              }
            }

            ~div {
              color: $next-color;

              &:before {
                background: $next-color;
                padding: $bullet-radius * 0.5;
              }

              &:after {
                height: 0%;
                transition: none;
              }
            }
          }

          // Dot
          &:before {
            content: "";
            background: $strip-color;
            padding: $bullet-radius;
            border-radius: 0%;
            position: absolute;
            left: -$gap;
            top: $line-height/2;
            transform: translateX(-50%) translateY(-50%);
            transition: padding 0.2s ease;
          }

          // Line
          &:after {
            content: "";
            background: $strip-color; //rgba(0, 0, 0, 0.6);
            border-radius: 2px;
            position: absolute;
            left: -$gap;
            top: $line-height/2;
            height: 101%;
            width: $line-thick;
            transform: translateX(-50%);
            transition: height 0.2s ease;
          }
        }
      }

      div {
        flex: 1;
        //outline: 1px solid rgba(0, 0, 0, 0.1);
        position: relative;
        line-height: $line-height;
        cursor: default;
        min-height: 30px;

        &:last-of-type {
          flex: 0;
        }
      }
    }
  }


  .done.current {
    color: #62af0b !important;

    &:before {
      background: #62af0b !important;
    }
  }

  .dark .done.current {
    color: lightgreen !important;

    &:before {
      background: lightgreen !important;
    }


  }

  .stepTime {
    color: rgb(132, 132, 132);
    margin-top: 0.3rem !important;

    i {
      padding-right: 10px;
    }
  }

  .success {
    color: green;
  }

  .errorText {
    margin-top: -0.5rem;
  }


  @import url('https://fonts.googleapis.com/css?family=Lato');

  body {
    margin: 0;
    padding: 0;
    font-family: 'Lato', Arial, sans-serif;
    display: flex;
  }

}


.visitCompletedMessgae {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;

  p {
    font-size: 1.5rem;
    font-weight: 600;
  }
}